import firebase from "firebase/compat/app";
import "firebase/compat/database";
let config ={
  apiKey: "AIzaSyD5glPSPEAf5PARDf5-_L2m6u76uco6BfA",
  authDomain: "estado-sede.firebaseapp.com",
  databaseURL: "https://estado-sede-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "estado-sede",
  storageBucket: "estado-sede.appspot.com",
  messagingSenderId: "286347567520",
  appId: "1:286347567520:web:458fb56f986bca14fd44c5",
  measurementId: "G-C94EQ4L921"
};
firebase.initializeApp(config);
export default firebase.database();
/*
{
  apiKey: "AIzaSyD5glPSPEAf5PARDf5-_L2m6u76uco6BfA",
  authDomain: "estado-sede.firebaseapp.com",
  databaseURL: "https://estado-sede-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "estado-sede",
  storageBucket: "estado-sede.appspot.com",
  messagingSenderId: "286347567520",
  appId: "1:286347567520:web:458fb56f986bca14fd44c5",
  measurementId: "G-C94EQ4L921"
};
*/
